export const dspLeadGenMixin = {
    data: () => ({
        columns: [],
        loading: false,
        paginated_leads: [],
        display_columns: [],
        pagination: {
            page: 1,
            rowsPerPage: 10,
            totalItems: 0,
            from: 0,
            to: 0,
            total: 0,
        },
    }),
    computed: {
        leads() {
            let leads = [];
            if (this.campaigns) {
                leads = this.$store.state.dsp.campaign_leads
            } else { leads = [] }
            return leads;
        },
        campaigns() {
            let grouped_campaigns = [];
            if (this.$route.name == 'truecaller-leadgen') {
                grouped_campaigns = this.$store.state.dsp.leads_campaigns.filter(item => item.channel == 'truecaller');
            } else if (this.$route.name == 'leadgen') {
                grouped_campaigns = this.$store.state.dsp.leads_campaigns.filter(item => item.channel == 'programmatic');
            } else if (this.$route.name == 'transsion-leadgen') {
                grouped_campaigns = this.$store.state.dsp.leads_campaigns.filter(item => item.channel == 'transsion');
            } else if (this.$route.name == 'phoenix-leadgen') {
                grouped_campaigns = this.$store.state.dsp.leads_campaigns.filter(item => item.channel == 'phoenix');
            } else if (this.$route.name == 'opera-leadgen') {
                grouped_campaigns = this.$store.state.dsp.leads_campaigns.filter(item => item.channel == 'opera');
            }else if (this.$route.name == 'ctv-leadgen') {
                grouped_campaigns = this.$store.state.dsp.leads_campaigns.filter(item => item.channel == 'ctv');
            }
            return grouped_campaigns
        },
    },

    created() {
        this.LeadgenCampaigns();
    },
    methods: {
        triggerPageChange(params) {
            this.pagination.page = params.page;
            this.getCampaignLeads(params);
        },
        async getCampaignLeads(params) {
            try {
                this.loading = true;
                await this.$store.dispatch("dsp/campaignLeads", params);
                this.loading = false;
            } catch (error) {
                this.error = error
                this.loading = false;
            }
        },
        async LeadgenCampaigns() {
            try {
                this.loading = true;
                await this.$store.dispatch("dsp/LeadgenCampaigns");
                this.loading = false;
            } catch (error) {
                this.error = error
                this.loading = false;
            }
        },

        async downloadReport(id) {
            this.$store.commit("dsp/clearReportLink");

            try {
                this.loading = true;
                await this.$store.dispatch("dsp/LeadsReports", id);
                this.loading = false;
            } catch (error) {
                this.error = error;
                this.loading = false;
            }
        },
    },
    watch: {
        leads() {
            if (this.campaigns.length) {
                this.columns = this.leads.data[0].display_columns;
                // this.columns.unshift("Time");
                this.paginated_leads = this.leads.data;

                this.pagination.page = this.leads.current_page;
                this.pagination.totalItems = this.leads.last_page;
                this.pagination.rowsPerPage = this.leads.per_page;
                this.pagination.from = this.leads.from;
                this.pagination.to = this.leads.to;
                this.pagination.total = this.leads.total;
            } else {
                this.paginated_leads = [];
                this.pagination.page = 0;
                this.pagination.totalItems = 0;
                this.pagination.rowsPerPage = 0;
                this.pagination.from = 0;
                this.pagination.to = 0;
                this.pagination.total = 0;
            }
        },
    },
}