<template>
  <div>
    <leadgen-table
      :campaigns="campaigns"
      :leads="paginated_leads"
      :pagination="pagination"
      :loading="loading"
      :columns="columns"
      @getCampaignLeads="getCampaignLeads($event)"
      @downloadReport="downloadReport($event)"
      @triggerPageChange="triggerPageChange($event)"
    />
  </div>
</template>

<script>
import LeadgenTable from "../../../components/Tables/LeadgenTable.vue";
import { dspLeadGenMixin } from "../../../mixins/dspLeadgenMixin";

export default {
  data: () => ({}),
  components: {
    LeadgenTable,
  },
  mixins: [dspLeadGenMixin],
};
</script>