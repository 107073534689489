<template>
  <v-card outlined>
    <v-card-title>
      <v-row align="center">
        <v-col cols="3">
          <!-- <v-btn
            :disabled="downloadable"
            depressed
            :href="reportLink"
            download
            color="primary"
          >
            Download Report
            <v-icon right dark> mdi-cloud-download </v-icon>
          </v-btn> -->

          <v-btn
            :disabled="downloadable"
            depressed
            :href="reportLink"
            target="_blank"
            color="primary"
          >
            Download Report
            <v-icon right dark> mdi-cloud-download </v-icon>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col class="d-flex" cols="4">
          <v-select
            :items="campaigns"
            v-model="selected_campaign"
            item-text="name"
            return-object
            dense
            outlined
            hint="Select campaign"
            persistent-hint
            :menu-props="{ bottom: true, offsetY: true }"
            hide-details=""
            label="Select Campaign"
          ></v-select>
        </v-col>
        <v-col class="d-flex" cols="4">
          <v-select
            v-model="selectedHeaders"
            :items="display_columns"
            return-object
            dense
            outlined
            item-text="text"
            :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }"
            hide-details
            label="Select headers"
            multiple
          ></v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="leads_with_date"
      :search="search"
      :loading="loading"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      loading-text="Loading... Please wait"
    >
    </v-data-table>
    <v-divider></v-divider>
    <v-card-actions class="d-flex align-center pa-4" v-if="campaigns">
      <div class="d-flex align-center">
        <div class="caption mr-3">
          Showing {{ pagination.from }} - {{ pagination.to }} of
          {{ pagination.total }}
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-pagination
        v-model="pagination.page"
        :length="pagination.totalItems"
        :total-visible="10"
        color="primary"
        class="custom"
        @input="handlePageChange"
      ></v-pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    campaigns: {
      type: Array,
    },
    leads: {
      type: Array,
    },
    columns: {
      type: Array,
    },
    pagination: Object,
    loading: Boolean,
  },
  data() {
    return {
      search: "",
      selected_campaign: null,
      selectedHeaders: [],
      downloadable: true,
      selected_campaign_id: null,
      display_columns: [],
      headers: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headersMap: [
        {
          text: "Campaign Id",
          align: "start",
          sortable: false,
          value: "campaign_id",
        },
        {
          text: "Time",
          align: "start",
          sortable: false,
          value: "created_at",
        },
      ],
      leads_with_date: [],
    };
  },

  computed: {
    reportLink() {
      return this.$store.state.dsp.report_link;
    },
  },
  watch: {
    campaigns() {
      this.selected_campaign = this.campaigns[0];
    },
    columns() {
      this.headers = [];
      this.columns.forEach((element) => {
        const value = `data.${element}`;
        const header = {
          text: element,
          align: "start",
          sortable: false,
          value: value,
        };
        this.headers.push(header);
      });
      const value = `created_at`;
      const header = {
        text: "Time",
        align: "start",
        sortable: false,
        value: value,
      };
      this.headers.unshift(header);
      this.display_columns = this.headers;
      this.selectedHeaders = this.display_columns;
    },
    selected_campaign() {
      this.selected_campaign_id = this.selected_campaign.id;
      let params = {
        campaign_id: this.selected_campaign_id,
        page: 1,
      };

      this.$emit("getCampaignLeads", params);
      this.downloadable = false;
      this.$emit("downloadReport", this.selected_campaign_id);
    },
    selectedHeaders() {
      this.headers = this.selectedHeaders;
    },
    leads() {
      this.leads_with_date = this.leads.map((lead) => ({
        ...lead,
        created_at: this.changeDateFormat(lead.created_at),
      }));
    },
  },
  methods: {
    handlePageChange(value) {
      this.pagination.page = value;
      let params = {
        campaign_id: this.selected_campaign_id,
        page: this.pagination.page,
      };
      this.$emit("triggerPageChange", params);
    },
    changeDateFormat(isoStringDate) {
      return new Date(isoStringDate).toLocaleString("en-us", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
  },
};
</script>
